import React from 'react'
import { Link as RebassLink } from 'rebass/styled-components'
import { Link as GatsbyLink } from 'gatsby'
import styled from 'styled-components'

const StyledGatsbyLink = styled(props => <GatsbyLink {...props} />)`
  color: ${({ theme }) => theme.colors.orange};
  &:hover,
  &:visited {
    color: ${({ theme }) => theme.colors.orange};
  }
`

const Link = ({ href, to, ...rest }) => {
  const link = href || to
  if (/^\//.test(link)) {
    return <StyledGatsbyLink to={link} {...rest} />
  }
  return <RebassLink href={link} {...rest} />
}
export default Link
