import React, { useMemo, useRef, useState } from "react";
import ReactCarousel from "react-multi-carousel";
import { Flex, Box } from "rebass/styled-components";
import theme from "../theme";
import ArrowLeft from "../components/icons/Arrow Left.svg";
import ArrowRight from "../components/icons/Arrow Right.svg";
import "react-multi-carousel/lib/styles.css";

const ArrowButton = props => <Box sx={{ cursor: "pointer" }} py={1} role="button" {...props} />;
const CarouselNav = ({ onPrevClick, onNextClick, ...rest }) => (
  <Flex justifyContent="space-between" alignItems="center" {...rest}>
    <ArrowButton onClick={onPrevClick}>
      <ArrowLeft style={{ height: "24px", width: "24px" }} color={theme.colors.black} />
    </ArrowButton>
    <ArrowButton onClick={onNextClick}>
      <ArrowRight style={{ height: "24px", width: "24px" }} color={theme.colors.black} />
    </ArrowButton>
  </Flex>
);
const Carousel = ({ showNavButtons, navProps = {}, containerMargin, ...rest }) => {
  const carouselRef = useRef(null);

  let Nav;
  if (showNavButtons) {
    const handleNext = () => carouselRef.current && carouselRef.current.next();
    const handlePrev = () => carouselRef.current && carouselRef.current.previous();
    Nav = <CarouselNav {...navProps} onPrevClick={handlePrev} onNextClick={handleNext} />;
  }
  return (
    <>
      {Nav}
      <ReactCarousel
        ref={carouselRef}
        itemClass="carousel-item"
        containerClass="carousel-container"
        sliderClass="carousel-slider"
        {...rest}
      />
    </>
  );
};

export default Carousel;
