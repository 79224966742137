import React, { useMemo, useRef, useState } from 'react'
import { Box, Flex, Text, Heading, Button } from 'rebass/styled-components'
import { Link } from 'gatsby'
import Img from './SanityImage'
import { Grid, GridChild } from './GridLayout'
import styled from 'styled-components'
import ArrowLeft from '../components/icons/Arrow Left.svg'
import ArrowRight from '../components/icons/Arrow Right.svg'
import Carousel from './Carousel'
import { Container } from './UI'
import useProjects from '../hooks/useProjects'
import { H4, H5 } from './Type'

const Project = ({ node }) => (
  <Box
    width={['auto', 'auto', 1 / 3]}
    flexShrink={0}
    pl={['1.5rem', '2rem', 1]}
    pr={[0, 0, 1]}
  >
    <Link
      to={`/projects/${node.slug.current}`}
      style={{ textDecoration: 'none' }}
    >
      <Box>
        <Img
          node={node._rawPreviewImg || node._rawHeroImg}
          disableMobileResizing={false}
        />
        <Box p={'14px'} mt={[0, 1]}>
          <H5 mb={['0.5rem', 1]} color='textColor'>
            {node.title}
          </H5>
          <Text sx={{ opacity: 0.8 }} color='textColor'>
            {node.description || node.heroHeading}
          </Text>
        </Box>
      </Box>
    </Link>
  </Box>
)

const ProjectCarousel = ({ projects }) => {
  return (
    <Carousel
      showNavButtons
      navProps={{
        px: ['1.5rem', '2rem']
      }}
      partialVisible={true}
      responsive={{
        small: {
          partialVisibilityGutter: 90,
          breakpoint: { max: 320, min: 0 },
          items: 1,
          slidesToSlide: 1
        },
        smedium: {
          partialVisibilityGutter: 125,
          breakpoint: { max: 450, min: 320 },
          items: 1,
          slidesToSlide: 1
        },
        medium: {
          partialVisibilityGutter: 200,
          breakpoint: { max: 640, min: 450 },
          items: 1,
          slidesToSlide: 1
        },
        mediumUp: {
          partialVisibilityGutter: 220,
          breakpoint: { max: 10000, min: 640 },
          items: 1,
          slidesToSlide: 1
        }
      }}
      arrows={false}
      showDots={false}
      infinite={true}
    >
      {projects.map(node => (
        <Project key={node.id} node={node} />
      ))}
    </Carousel>
  )
}

const filterProjects = projects => projects.slice(0, 3)

const FeaturedProjectsSection = props => {
  const projects = useProjects(filterProjects)

  return (
    <Flex
      bg='bgColor'
      minHeight='93vh'
      justifyContent='center'
      flexDirection='column'
    >
      <Grid
        minHeight='auto'
        px={[2, 2, 4, 9]}
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateRows: 'auto'
        }}
      >
        <GridChild
          colStart={1}
          colEnd={[3, 7]}
          textAlign='center'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <H4>{props.heading}</H4>
        </GridChild>
        <GridChild colStart={1} colEnd={[3, 7]}>
          <Box
            mx={[-2]}
            sx={{
              display: ['block', 'block', 'none'],
              overflow: 'visible'
            }}
          >
            <ProjectCarousel projects={projects} />
          </Box>
          <Box
            mx={-1}
            sx={{
              display: ['none', 'none', 'flex'],
              flexWrap: 'nowrap'
            }}
          >
            {projects.map(node => (
              <Project key={node.id} node={node} />
            ))}
          </Box>
        </GridChild>

        <GridChild
          colStart={1}
          colEnd={[3, 7]}
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ width: ['100%', 'auto'] }}>
            <Link to='/projects' style={{ textDecoration: 'none' }}>
              <Button
                py='1.5rem'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Text fontWeight={400}>See All Work</Text>
                <ArrowRight
                  ml={3}
                  color={'beige'}
                  style={{
                    height: '1.5rem',
                    width: '1.5rem'
                  }}
                />
              </Button>
            </Link>
          </Box>
        </GridChild>
      </Grid>
    </Flex>
  )
}

FeaturedProjectsSection.defaultProps = {
  heading: `What have we been up to?`
}

export default FeaturedProjectsSection
